.spreadsheet-info-wrapper {
	width: 100%;

	.info-wrapper {
		width: 100%;
		display: flex;
		flex-direction: row;
		gap: 20px;

		.spreadsheet-img-wrapper {
			min-width: fit-content;
			margin-bottom: 24px;
			overflow: hidden;
			filter: $card-box-shadow;

			img {
				border-radius: 10px;
				height: 100%;
			}
		}

		.info-card-wrapper {
			width: 100%;

			.dashboard-info-card {
				height: 118px;
				width: 100%;
				margin: 0 0 24px 0;

				.ant-card-body-wrapper-2 {
					justify-content: center;
					align-items: flex-start;
					padding: 35px 24px;
					flex-direction: column;
					gap: 10px;

					.ant-card-body-info {
						font-size: 18px;
					}

					.ant-card-title,
					.ant-card-body-info-2 {
						font-size: 14px;
					}
				}
			}
		}

		.source-image-cover {
			width: 100%;
			overflow: hidden;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 10px;
			background: white;
			filter: $card-box-shadow;
			margin-bottom: 22px;

			img {
				max-width: 230px;
				display: block; /* remove extra space below image */
				margin: 10px;
			}
		}
	}

	.sub-header {
		font-size: 18px;
		font-weight: 500;
		margin: 24px 0 4px 0;
		font-family: 'Poppins', sans-serif;
	}

	.info-wrapper-2 {
		width: 100%;
		display: flex;
		justify-content: space-between;
		gap: 24px;

		.info-box {
			height: 90px;
			width: 100%;
			margin: 20px 0;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 24px;
			filter: $card-box-shadow;
			background: white;
			border-radius: 10px;

			.label {
				font-size: 14px;
				color: $base-color-text-6;
				font-weight: 500;
			}
			.value {
				font-size: 14px;
				font-weight: 500;

				.unit {
					margin-left: 5px;
					color: $base-color-text-6;
				}
			}
		}
	}
}
