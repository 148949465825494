@mixin status-info {
  border-radius: 8px;
  padding: 5px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  width: fit-content;
}

.icon-running {
  color: #59ad9e;
  font-size: 20px;
  padding-right: 5px;
}

.icon-map-pin {
  color: $base-color;
  font-size: 34px !important;
  transform: translate(-50%, -50%);
}

.text-running {
  color: #59ad9e;
  font-size: 14px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
}

.icon-search {
  font-size: 19px;
}

.icon-arrow-bck {
  font-size: 24px;
}

.ant-scroll-number-only-unit {
  color: white;
  font-weight: 600;
  line-height: 20px;
}

.icon-bell {
  font-size: 19px;
  color: $base-color-text-6;
  &:hover {
    color: $base-color-2-hover;
  }
}

.icon-add {
  font-size: 17px;
  padding-right: 8px;
}

.icon-export {
  @extend .icon-add;
  filter: invert(64%) sepia(13%) saturate(942%) hue-rotate(177deg) brightness(88%) contrast(85%);
}

.icon-arrow {
  font-size: 14px;
  padding-left: 5px;
}

.icon-paused {
  color: $delete-btn-hover;
  font-size: 20px;
  padding-right: 5px;
}

.text-paused {
  color: $delete-btn-hover;
  font-size: 14px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
}

.icon-minus-blue {
  font-size: 16px;
  color: $chart-color1;
}

.icon-minus-green {
  font-size: 16px;
  color: $chart-color2;
}

.icon-minus-blue-light {
  font-size: 16px;
  color: $chart-color3;
}

.icon-minus-orange {
  font-size: 16px;
  color: $chart-color-sim;
}

.icon-minus-yellow {
  font-size: 16px;
  color: $chart-color4;
}

.anticon anticon-search ant-input-search-icon {
  color: $base-color;
}

.ellipsis-icon {
  font-size: 30px;
  color: $base-color-text-2;
  cursor: pointer;
}

.right-outlined-icon {
  font-size: 14px;
  color: $base-color3;
  cursor: pointer;
}

.right-outlined-icon-selected {
  font-size: 14px;
  color: $base-color-text-4;
  cursor: pointer;
}

.ant-alert-info .ant-alert-icon {
  color: $info-alert-color;
  font-size: 20px;
}

.ant-alert-info {
  background-color: $info-alert-color-bg;
  border: 1px solid $base-color-2;
  filter: $card-box-shadow;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ant-alert-description {
  color: $info-alert-color;
  font-weight: 500;
}

// Arrow-custom
.arrow {
  position: absolute;
  top: 50%;
  width: 1vmin;
  height: 1vmin;
  background: transparent;
  border-top: 0.2vmin solid $base-color-text-6;
  border-right: 0.2vmin solid $base-color-text-6;
  box-shadow: 0 0 0 lightgray;
  transition: all 200ms ease;

  &.left {
    left: -3;
    transform: translate3d(0, -50%, 0) rotate(-135deg);
  }

  &.right {
    right: -3;
    transform: translate3d(0, -50%, 0) rotate(45deg);
  }

  &:hover {
    border-color: $avatar-color;
    box-shadow: 0.2vmin -0.2vmin 0 $base-color-text-6;
  }

  &:before {
    // for better hit area
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(15%, -60%) rotate(45deg);
    width: 300%;
    height: 2000%;
  }
}

.connected-info {
  @include status-info;
  background-color: $connected-color;
  color: $connected-color-text;
  font-size: 15px;
}

.offline-info {
  @include status-info;
  background-color: $offline-color;
  color: $offline-color-text;
  font-size: 15px;
}

.simulated-info {
  @include status-info;
	color: $chart-color-sim;
	background: $offline-color;
  font-size: 15px;
}

.avatar {
  display: flex;
  height: 100%;
  float: right;
  align-items: center;
  cursor: pointer;
}

.nav-avatar {
  background-color: $avatar-color;
  color: white;
  display: inline-flex;
  align-items: center;
  vertical-align: inherit;
}

.ant-avatar-string {
  font-size: 18px;
  font-weight: 500;
}

.ant-select-arrow {
  right: 15px;
  top: 19px;
  color: $base-color-text-6;
}

.sensors-icon {
  width: 14px;
  min-width: 14px;
}

.sensors-icon path {
  fill: $base-color;
}
