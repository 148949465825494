.bold {
    font-weight: 700;
}

.small {
    font-size: 12px;
    line-height: 16px;
}

.small-grey {
    font-size: 12px;
    color: $base-color-text-6;
}

.medium{
    font-weight: 500;
    font-size: 18px; 
}

a {
    outline: none !important;
    font-weight: 400;
    color:$base-color;
    font-size: 20px;
    line-height: 20px;
    &:hover {
        text-decoration: none;
    }
}

h1 {
    font-weight: 800;
    color:$base-color;
    line-height: 20px;
    @include breakPoint(small) {
        @include font-size(24px);
    }
}

h2 {
    color:$base-color;
    font-size: 20px;
    line-height: 20px;
    font-weight: bold;   
    @include breakPoint(small) {
        @include font-size(28px);
    }
}

h3 {
    font-weight: 500;
    color:$base-color;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    @include breakPoint(small) {
        @include font-size(24px);
    }
}

h4 {
    color:$base-color;
    font-size: 20px;
    line-height: 20px;
    @include breakPoint(small) {
        @include font-size(15px);
    }
}

h5 {
    color:$base-color;
    font-size: 26px;
    line-height: 20px;
    font-weight: normal;
    @include breakPoint(small) {
        @include font-size(14px);
    }
}

p,
li {
    font-weight: 400;
    color:$base-color;
    font-size: 20px;
    line-height: 20px;
    @include breakPoint(small) {
        @include font-size(24px);
    }
}