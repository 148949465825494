.image-3d-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 4px rgba(35, 54, 58, 0.08);
  border-radius: 10px;
  background: #ffffff;
  margin: 12px 0;

  .main-image {
    max-width: 1000px;
    width: 100%;
    height: 100%;
    padding-bottom: 24px;
  }

  map {
    cursor: pointer;
  }

  .sensorCounter {
    width: 45px;
    position: absolute;

    &.agricultural_0 {
      top: 28%;
      left: 12%;
    }

    &.liquid_0 {
      top: 38%;
      left: 23%;
    }

    &.solid_0 {
      top: 47%;
      left: 60%;
    }

    &.pressure_right_90 {
      top: 20%;
      left: 57%;
    }

    &.pressure_left_90 {
      top: 21%;
      left: 38%;
    }

    &.flow_right_90 {
      top: 73%;
      left: 55%;
    }

    &.flow_left_90 {
      top: 74%;
      left: 40%;
    }

    &.agricultural_180 {
      top: 20%;
      left: 93%;
    }

    &.liquid_180 {
      top: 25%;
      left: 58%;
    }

    &.solid_180 {
      top: 31%;
      left: 32%;  
    }

    &.pressure_right_180 {
      top: 21%;
      left: 24%;
    }

    &.flow_right_180 {
      top: 42%;
      left: 25%;
    }


  }

  .divider {
    width: 1px;
    height: 40px;
    border-right: 1px solid #dbdbdb;
  }

  .image-3d-inner {
    position: relative;

    .marker {
      position: absolute;
      cursor: pointer;

      &.motor-0 {
        top: 37%;
        left: 47%;
      }

      &.motor-180 {
        top: 37%;
        left: 47%;
      }

      &.uad-0 {
        top: 50%;
        left: 45%;
      }

      &.uad-180 {
        top: 27%;
        left: 49%;
      }

      &.flare-0 {
        top: 47%;
        left: 19%;
      }

      &.flare-2-0 {
        top: 46%;
        left: 39%;
      }

      &.flare-180 {
        top: 26%;
        left: 72%;
      }

      &.flare-2-180 {
        top: 58%;
        left: 58%;
      }

      &.ucg-0 {
        top: 37%;
        left: 47%;
      }

      &.ucg-2-0 {
        top: 39%;
        left: 30%;
      }

      &.ucg-3-0 {
        top: 53%;
        left: 50%;
      }

      &.ucg-180 {
        top: 43%;
        left: 45%;
      }

      &.ucg-2-180 {
        top: 21%;
        left: 55%;
      }

      &.ucg-3-180 {
        top: 47%;
        left: 31%;
      }

      &.solidwaste {
        top: 40%;
        left: 67%;
      }

      &.liquidwaste {
        top: 60%;
        left: 53%;
      }

      &.agriculturalwaste {
        top: 48%;
        left: 46%;
      }

      &.pressuresensor {
        top: 61%;
        left: 67%;
      }

      &.pressuresensor-2 {
        top: 60%;
        left: 39%;
      }

      &.flowsensor {
        top: 56%;
        left: 59%;
      }

      &.flowsensor-2 {
        top: 56%;
        left: 43%;
      }
    }
  }

  .rotate-section-wrapper,
  .zoom-section-wrapper,
  .title-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 24px 0;

    .rotate-button-wrapper,
    .zoom-button-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      background: #ffffff;
      border: 1px solid #dbdbdb;
      box-sizing: border-box;
      border-radius: 6px;
      height: 40px;
    }
  }

  .zoom-section-wrapper {
    margin-bottom: 0;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    min-height: 88px;

    .title-wrapper, .virtual-tour-player {
      h3 {
        margin: 0;
      }
    }

    .virtual-tour-player {
      display: flex;
      align-items: center;
      border: 1px solid #dbdbdb;
      border-radius: 6px;
      height: 40px;
      padding: 0 10px;
      cursor: pointer;
    }

    .zoom-button-wrapper {
      width: 81px;

      .back-arrow {
        padding-right: 5px;
      }

      > span {
        cursor: pointer;
        font-weight: 500;
        &:hover {
          color: $base-color-text;
        }
      }
    }

    .title-wrapper {
      padding: 0;
    }
  }

  .rotate-section-wrapper {
    margin-top: 0;

    &.zoomed-out {
      padding-top: 24px;
      z-index: 5;
    }

    .rotate-button-wrapper {
      width: 129px;

      > img {
        padding: 11px 23px;
        cursor: pointer;
      }
    }
  }
}

.ant-popover-inner {
  border: 1px solid #d4d4d4;
  box-shadow: 0px 2px 8px rgba(36, 37, 41, 0.12);
  border-radius: 10px;
}

.sensor-map-popup-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 480px;
  height: 260px;

  .sensor-map-popup-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    border-bottom: 1px solid #f2f2f2;
    padding: 0 20px 12px 20px;

    font-weight: 500;
    font-size: 18px;
  }

  .sensor-map-popup-body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;

    .sensor-map-popup-entry {
      width: 43%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin: 10px 0;

      .sensor-map-popup-entry-name {
        font-weight: 500;
        color: $base-color-text-6;
      }

      .sensor-map-popup-entry-value {
        font-weight: 500;
        font-size: 16px;
        line-height: 25px;
      }
    }
  }

  .sensor-map-popup-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70px;
    border-top: 1px solid #f2f2f2;
    padding-top: 12px;
  }
}

.popup-close {
  background: transparent;
  color: $base-color-text;
  width: fit-content;
  height: fit-content;
  &:hover {
    color: $base-color;
    background: transparent;
  }
}

.map-rotate-btn {
  font-weight: 500;
  cursor: pointer;
  &:hover {
    color: $base-color-text;
  }
}
