// helpers
@import './helpers/globals';

// components
@import './components/button';
@import './components/disclaimer';
@import './components/form';
@import './components/header';
@import './components/table';
@import './components/modal';
@import './components/text';
@import './components/icons';
@import './components/charts';
@import './components/sensormap';
@import './components/sidebar';
@import './components/spreadsheet';

// pages
@import './pages/site';
@import './pages/analytics';
@import './pages/login';
@import './pages/overview';
@import './pages/sensor';
@import './pages/architecture';

@import '~react-modal-video/scss/modal-video.scss';

html {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden !important;
  height: 100%;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div,
span,
p,
label,
input,
select,
section,
footer,
header,
nav,
ul,
li,
button,
tr,
td,
th {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}

label {
  font-weight: 500;
}

* {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}

#root {
  min-height: 100vh;
}

a {
  text-decoration: none;
  color: #108db8;
}

code {
  user-select: text;
  background: rgba(0, 0, 0, 0.05);
  padding: 2px 5px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  font-size: 0.8em;
  line-height: 1.5;
}

label {
  font-weight: 500;
}

.divider {
  -webkit-box-shadow: 0 8px 6px -6px black;
  -moz-box-shadow: 0 8px 6px -6px black;
  box-shadow: 0 8px 6px -6px black;
}

.hidden {
  display: none !important;
}

.no-scroll {
  height: 100%;
  overflow: hidden;
}

button {
  outline: none !important;
}

.page-wrapper {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: $base-color2;
}

#root {
  overflow-x: scroll;
}

.main-section {
	height: 100vh;
	width: 100%;
	min-width: 989px;
	display: flex;
	flex-direction: column;
  justify-content: space-between;
	align-items: center;
	overflow-x: auto;
	background-color: $base-color2;
  padding-bottom: 35px;
	.content {
		width: 100%;
	}
}

.spinner {
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	left: calc(50% + 120px);
}

.ant-spin-dot-item {
  background-color: $base-color-2;
}

.apexcharts-bar-series.apexcharts-plot-series  .apexcharts-series path {
  clip-path: inset(0% 0% -20% 0% round 2px);
}

.apexcharts-rangebar-series.apexcharts-plot-series  .apexcharts-series path {
  clip-path: inset(5% 5% 5% 0% round 2px);
}

.modal-video-close-btn {
  &:after, &:before {
    margin-top: -1px;
  }
}