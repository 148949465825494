.apexcharts-tooltip {
  background: #f3f3f3;
  color: $base-color;
}

.sensor-info-card-chart {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 382px;
  margin: 10px 0 10px 0;
  filter: $card-box-shadow;
  .ant-card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    height: 100%;
    width: 100%;
  }
}

.dashboard-info-card-chart {
  display: flex;
  height: 375px;
  margin: 10px 0 10px 0;
  filter: $card-box-shadow;
  .ant-card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    height: 100%;
    width: 100%;
  }
}

.chart-body {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 20px 20px 20px;
}

.chart-body-2 {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 0;
  padding-top: 0;
}

.chart-body-data {
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 160px;
  position: absolute;
  z-index: 1;
}

.chart-body-no-padding {
  height: 300px;
  width: 100%;
  padding: 0;
  margin: 0;
}

.chart-body-wrapper {
  height: 100%;
  width: 100%;
}

.chart-body-wrapper-3 {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chart-body-no-padding svg {
  border-radius: 10px;
}

.gauge-chart {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 50px 50px 50px;
}

.flowrate-chart-title {
  color: $base-color-2;
}
