// Login-V3

.login-main-section-v3 {
  position: fixed;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  overflow-x: hidden;
  background-color: white;
}

.login-scribehub-wrapper-v3 {
  width: 40%;
  min-width: 514px;
  height: 100vh;
  left: 0;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding-left: 80px;

  .login-logo-wrapper {
    position: absolute;
    align-self: flex-start;
    margin-top: 80px;
  }

  .login-scribehub {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h2 {
      font-weight: 500;
      font-size: 36px;
      line-height: 1.2;
      color: $base-color;
    }

    .info-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 40px;
      margin-top: 15px;

      p {
        line-height: 20px;
        color: $base-color-text-6;
        margin-bottom: 5px;
      }

      .request-access-link {
        font-weight: bold;
        font-size: 14px;
        color: $base-color-2;
        &:hover {
          color: $base-color-2-hover;
        }
      }
    }
  }
}

.login-footer-wrapper {
  position: fixed;
  bottom: 0;
  margin-bottom: 50px;
}

.login-welc-wrapper {
  display: flex;
  width: 100%;
  object-fit: fill;
  overflow: hidden;
  right: 0;
  img {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
  }
}

.login-logo {
  width: 260px;
}

.site-header-logo {
  width: 180px;
}

.scribehub-logo {
  width: 28px;
}

.login-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 500px;
  width: 30%;
  height: 80%;
  @include breakPoint(small) {
    min-width: unset;
  }
}

.google-logo {
  width: 20px;
}

.login-card {
  display: flex;
  height: 240px;
  box-shadow: 0px 2px 4px rgba(35, 54, 58, 0.08);
  .ant-card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
  }
}

.ant-card-title-login {
  font-weight: 500;
  font-size: 23px;
}

.ant-card-title-wrapper-login {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px 0 5px 0;
}

.ant-card-body-wrapper-login {
  width: 100%;
  height: 100%;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.ant-card-subbody-wrapper-login {
  width: 100%;
  height: 100%;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.forgot-link {
  display: flex;
  justify-content: flex-end;
  color: #979797;
  font-size: 14px;
}

.ant-form-item-mb {
  margin-bottom: 5px;
}

.hide-fields {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
}

.reveal-fields {
  overflow-y: hidden;
  max-height: 500px;
  transition-property: all;
  transition-duration: 2.5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

// Login-V1-JWT

.login-main-section {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: auto;
  height: 100vh;
  background-color: $base-color2;
}

.login-scribehub-wrapper {
  position: absolute;
  top: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  left: 50%;
  display: flex;
  flex-direction: column;

  .login-scribehub {
    width: 480px;
    height: 240px;
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(35, 54, 58, 0.08);
    border-radius: 10px;
    z-index: 5;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px 0;
    margin-bottom: 50px;

    h2 {
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
    }

    .info-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 40px;

      p {
        line-height: 20px;
        color: #828387;
        margin-bottom: 5px;
      }

      .request-access-link {
        font-weight: bold;
        font-size: 14px;
        color: #828387;
      }
    }

    .login-scribehub-link {
      @include custom-btn;
      color: white;
      width: 247px;
      height: 48px;
      background: #40b0cf;
      box-shadow: 0px 2px 4px rgba(58, 108, 121, 0.12);
      border-radius: 100px;
    }
  }

  .switch-mode-btn {
    align-self: center;
    width: 250px;
  }
}

.login-cta-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
