.dashboard-info-card-chart-analytics {
  display: flex;
  height: 375px;
  margin: 10px 0 10px 0;
  filter: $card-box-shadow;
  .ant-card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    height: 100%;
    width: 100%;
  }
}

.analytics-setup-card {
  display: flex;
  height: 100%;
  margin: 10px 0 10px 0;
  filter: $card-box-shadow;
  .ant-card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    height: 100%;
    width: 100%;
  }
}

.analytics-parameters-card {
  display: flex;
  height: 110px;
  margin: 10px 0 10px 0;
  filter: $card-box-shadow;
  .ant-card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    height: 100%;
    width: 100%;
  }
}

.ant-card-title-wrapper-analytics {
  width: 100%;
  padding: 15px 20px 0 20px;
  display: flex;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.analytics-list-row-wrapper {
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.chart-body-wrapper-analytics {
  height: 100%;
  width: 100%;
  padding: 0;
}
