.rounded-input {
  border-radius: 10px;
  height: 40px;
  width: 100%;
}

.rounded-input-analytics {
  border-radius: 10px;
  height: 40px;
}

.rounded-input-select {
  height: 40px;
  width: 100%;
  border: 1.4px solid $select-border-color;
  border-radius: 20px;
}

.ant-form-item-control {
  border-radius: 10px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 12px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $base-color3;
}

.select-rounded {
  width: 81px;
}

.select-day-rounded {
  width: 109px;
  border: 1px solid $base-color4;
  border-radius: 12px;
}

.select-day-rounded-dashboard {
  width: 140px;
  color: $base-color-text-6;
  border: 1px solid $base-color4;
  border-radius: 12px;
  font-weight: 400;
}

.select-rounded-log {
  width: 180px;
  color: $base-color-text-6;
  border: 1px solid $base-color4;
  border-radius: 12px;
  font-weight: 400;
}

.select-day-rounded-analytics {
  width: 140px;
  color: $base-color-text-6;
  filter: $card-box-shadow;

  span {
    font-weight: 400;
    font-family: 'Poppins', sans-serif;;
  }
}

.select-sensors-rounded {
  width: 188px;
}

.notification-wrapper {
  margin-top: 10px;
}

.ant-select-dropdown {
  border-radius: 10px;
}

.ant-form-item-control-input-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
  border-radius: 10px;
  height: 35px;
}

.ant-row {
  flex-flow: unset;
}

.ant-switch {
  width: 55px;
  background-color: $delete-btn-hover;

  &:hover {
    background-color: rgb(170, 170, 170);
  }

  &.ant-switch-checked {
    background-color: #59ad9e;
  }
}

.ant-form-item-children-icon {
  display: none;
}

.new-sensor-page-footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.analytics-form-footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 20px;
}

.sensor-form-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 15px 55px 30px 55px;
}

.sensor-form {
  width: 100%;
  background-color: white;
  border-radius: 10px;
  filter: $card-box-shadow;
  padding: 30px 50px;
}

.parameters-form {
  width: 100%;
  background-color: white;
  border-radius: 10px;
  filter: $card-box-shadow;
  padding: 0 30px 30px 30px;
}

.form-row {
  border-bottom: 1px solid $select-border-color;
  padding: 30px 0;
}

.ant-row .ant-form-item {
  width: 100%;
}

.ant-form-item-label > label {
  position: relative;
  display: flex;
  padding-left: 5px;
}

@media (max-width: 1300px) {
  .ant-row .ant-form-item {
    width: 100%;
    flex-direction: column;
  }
}

.ant-input {
  border: 1px solid $base-color-text-3;
  &:hover {
    border: 1px solid $base-color-2-hover;
  }
  &:focus {
    border: 1px solid $base-color-2-hover;
    box-shadow: 0 0 0 2px $base-color3;
  }
}

.login-form {
  max-width: 350px;
  width: 70%;
}