@mixin custom-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 10;
	border-radius: 12px;
	height: 40px;
	color: $base-color;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	cursor: pointer;
}

button {
	color: $base-color;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	letter-spacing: 0.04em;
	border: none;
	border-radius: 32px;
	height: 52px;
	width: 110px;
	background-color: $base-color2;
	cursor: pointer;
	&:hover {
		background-color: $base-color-hover;
		border: none;
		color: white;
		transition: all 0.3s;
	}
}

.primary-btn {
	@include custom-btn;
	background: $base-color2;
	border: 1px solid $base-color2;
	width: 120px;
	height: 36px;
}

.back-btn {
	background-color: transparent;
	width: 190px;
	padding: 0 80px 0 0;
	border: none;
	box-shadow: none;
	&:hover {
		background-color: transparent;
	}
	.ant-btn {
		background: transparent;
		border: none;
		box-shadow: none;
		&:hover {
			background: transparent;
		}
		&:focus {
			background: transparent;
			border: none;
		}
	}
}

.edit-btn {
	@extend .back-btn;
	padding: 0 40px 0 0;
	width: fit-content;
}

.back-btn-2 {
	background-color: transparent;
	color: $base-color-2;
	width: fit-content;
	height: fit-content;
	&:hover {
		color: $base-color-2-hover;
		background-color: transparent;
	}
}

.notifications-btn {
	background-color: transparent;
	width: fit-content;
	padding-left: 0;
	&:hover {
		background-color: transparent;
		color: $avatar-color;
	}
}

.avatar-btn {
	background-color: transparent;
	width: 100%;
	padding: 0;
	&:hover {
		background-color: transparent;
		color: $avatar-color;
	}
}

.primary-btn-inverse {
	@include custom-btn;
	background-color: $base-color-2;
	color: white;
	width: 120px;
	&:hover {
		background-color: $base-color-2-hover;
		color: white;
	}
}

.primary-btn-white {
	@include custom-btn;
	background-color: white;
	color: $base-color-text-6;
	width: 120px;
	height: 38px;
	filter: $card-box-shadow;
	&:hover {
		background-color: $base-color5;
		color: $base-color-text-3;
	}
}

.primary-btn-white-2 {
	@extend .primary-btn-white;
	width: 160px;
	border: 1px solid $base-color-text-5;
	filter: none;
}


.primary-btn-white-3 {
	@extend .primary-btn-white;
	width: 170px;
	
}

.login-btn {
	@include custom-btn;
	background: transparent;
	border: 1px solid $base-color-text-3;
	color: $base-color-text-6;
	width: 180px;
	cursor: pointer;
	&:hover {
		color: $base-color-2-hover;
		background: transparent;
		border: 1px solid $base-color-2-hover;
		transition: all 0.2s;
	}
}

.primary-btn-sm {
	@include custom-btn;
	background-color: $base-color-2;
	color: white;
	width: 136px;
	&:hover {
		color: white;
		background: $base-color-2-hover;
	}
}

.primary-btn-md {
	@include custom-btn;
	background-color: $base-color-2;
	color: white;
	width: 156px;
	&:hover {
		color: white;
		background: $base-color-2-hover;
	}
}

.primary-btn-lg {
	@include custom-btn;
	background-color: $base-color-2;
	color: white;
	width: 166px;
	border: none;
	box-shadow: none;
	&:hover {
		color: white;
		background: $base-color-2-hover;
		border: none;
		transition: all 0.5s;
	}
	&:focus {
		color: white;
		background: $base-color-2-hover;
		border: none;
	}
}

.primary-btn-xlg {
	@extend .primary-btn-lg;
	width: 186px;
}

.primary-btn-xxlg {
	@extend .primary-btn-lg;
	width: 236px;
	height: 52px;
}

.logout {
	background: transparent;
	color: $base-color-text-2;
	font-size: 15px;
	&:hover {
		color: white;
		background: transparent;
		border: none;
		transition: all 0.5s;
	}
}

.google-primary-btn {
	background-color: $google-color;
	color: white;
	display: flex;
	align-items: center;
	justify-content: space-around;
	z-index: 10;
	border-radius: 20px;
	height: 40px;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	width: 210px;
	&:hover {
		background-color: $google-color-hover;
		border: none;
	}
}

.scribehub-btn {
	background-color: $scribehub-color;
	color: white;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	z-index: 10;
	border-radius: 30px;
	height: 48px;
	width: 247px;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	&:hover {
		background-color: $scribehub-color-hover;
		border: none;
	}
}

.cta {
	background: $base-color;
	border: 1px solid $base-color2;
	z-index: 10;
	border-radius: 29px;
	height: 52px;
	width: 120px;
	padding: 10px 10px;
	color: #ffffff;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	> span {
		color: #ffffff;
		font-weight: bold;
		font-size: 16px;
		line-height: 16px;
	}
}

.cta2 {
	background: $base-color;
	border: 1px solid $base-color2;
	z-index: 10;
	border-radius: 29px;
	height: 48px;
	padding: 5px 5px;
	color: #ffffff;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	width: 180px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	&:hover {
		border: none;
		color: white;
		transition: all 0.3s;
	}
}

.cta3 {
	color: $base-color;
	font-size: 40px;
	&:hover {
		color: $base-color-hover;
		border: none;
		transition: all 0.5s;
	}
}

.delete-button {
	color: $delete-btn;
	background-color: transparent;
	z-index: 10;
	height: 48px;
	padding: 5px 5px;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	width: 90px;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	&:hover {
		color: $delete-btn-hover;
		background: transparent;
		border: none;
		transition: all 0.5s;
	}
}

.cta-device {
	background: transparent;
	border: 1px solid $base-color;
	z-index: 10;
	border-radius: 29px;
	height: 32px;
	padding: 7px 12px;
	color: $base-color;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	width: 101px;
	.cta-device-withdraw {
		padding: 7px 18px;
	}
}

.cta-device-withdraw {
	background: transparent;
	border: 1px solid $base-color;
	z-index: 10;
	border-radius: 29px;
	height: 32px;
	padding: 6px 20px;
	color: $base-color;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	width: 101px;
}

.nonanimating-button {
	float: right;
	& [ant-click-animating-without-extra-node]:after {
		animation: 0s !important;
		// border-color: transparent !important;
	}
}

.marker-btn {
	background: none;
	border: none;
	cursor: pointer;
}

.ant-btn-primary {
	background: $base-color;
	border-radius: 20px;
	border: none;

	&:hover {
		background: $base-color-hover;
		border: none;
		transition: all 0.5s;
	}
}

.ant-btn-sm {
	border-radius: 10px;

	&:hover {
		background: $base-color-hover;
		color: white;
		border: none;
		transition: all 0.5s;
	}
}

.ant-btn {
	border: none;
}
