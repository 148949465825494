.login-header-wrapper {
  @include header-footer-wrapper;
  background-color: white;
  height: 70px;
  padding: 12px 80px;
  position: sticky;
  top: 0;
}

.site-header-wrapper {
  @include header-footer-wrapper;
  background-color: white;
  height: 70px;
  padding: 12px 60px;
  display: flex;
  border-bottom: 1px solid $base-color4;
  position: sticky;
  top: 0;
}

.input-search {
  border-radius: 20px;
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  border: 0;
}

.user-info-wrapper {
  margin-left: auto;
}

.site-sub-header-wrapper {
  @include header-footer-wrapper;
  background-color: transparent;
  height: 76px;
  padding: 20px 60px 0 60px;
}

.footer-wrapper {
	display: flex;
	justify-content: center;
	background-color: transparent;
	margin-top: 35px;
	position: relative;

  .powered-by-logo {
    filter: brightness(0) saturate(100%) invert(93%) sepia(88%) saturate(6547%) hue-rotate(176deg) brightness(102%) contrast(96%);
  }
}

.sub-header-wrapper {
  @include header-footer-wrapper;
  background-color: transparent;
  height: 76px;
  padding: 12px 10px;
  z-index: 1;
}

.selector-wrapper {
  @include header-footer-wrapper;
  background-color: transparent;
  justify-content: flex-end;
  height: fit-content;
  padding: 10px 20px;
}
