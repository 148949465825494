.arch-img-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 4px rgba(123, 148, 186, 0.2);
  border-radius: 10px;
  background: #ffffff;
  margin: 12px 60px;

  img {
    width: 100%;
    height: 100%;
    padding: 37px 86px;
  }
}