.ant-menu-inline {
  background-color: white;
  height: 100%;
  width: 250px;
  border-right: 1px solid $base-color4;
}

.ant-menu-inline .ant-menu-item {
  background-color: white;
  color: $base-color-text-3;
  padding: 30px 0;
  align-items: center;
  border-right: 1px solid $base-color4;

  &:hover {
    background-color: $base-color5;
    color: $base-color-text-3;
    border-right: 2px solid $base-color4;
  }
}

.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content {
  font-family: 'Poppins', sans-serif;
}

.ant-menu-inline .ant-menu-item::after {
  border: 1px solid $base-color-2;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: $base-color3;
  color: $base-color-2;

  a {
    color: $base-color-2;
  }
}

.ant-menu-item-selected svg path {
  fill: $base-color-2;
  background: $base-color-2;
}

.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  right: 5px;

  &:before {
    background: $base-color-text-2;
  }

  &:after {
    background: $base-color-text-2;
  }
}

.avatar-menu {
  border-radius: 8px;
  width: 200px;
}

.documentation-menu {
  border-radius: 8px;
  width: 160px;
}

.ant-menu-delete {
  color: $offline-color-text;
}

.avatar-menu-title-wrapper {
  padding: 10px 15px;
}

.avatar-menu-icons {
  margin-right: 8px;
  width: 18px;
  min-width: 14px;
}

.export-menu-icons {
  margin-right: 8px;
  width: 14px;
  min-width: 12px;
}

.avatar-menu-title-wrapper {
  padding: 10px 15px;
}

.ant-menu-item a {
  color: $base-color-text-3;
  font-size: 16px;

  &:hover {
    color: $base-color-text-3;
  }
}

.log-menu-items a {
  font-size: 14px;
  color: $base-color;
}

.sidebar-divider {
  margin: 5px 0;
  background: $base-color4;
}

.logo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
	height: 75.5px;
  border-bottom: 1px solid $base-color4;
}

.cut-text-submenu {
  @include cut-text-md;
  width: 165px;
  color: $base-color;
  margin: 0 auto;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}

.cut-text-xlg {
  @include cut-text-md;
  width: 200px;
  height: 20px;
  padding-left: 10px;
  vertical-align: middle;
  color: $base-color-text-6;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
}

.ant-menu-item .ant-menu-item-icon {
  margin-left: 10px;
}

.submenu {
  .ant-menu-inline .ant-menu-item {
    padding: 25px 0 25px 25px !important;
    margin: 0;
    
      &:hover {
        background-color: $base-color5;
        color: $base-color-text-3;
        border: none !important;
      }
  }

  .ant-menu-item .ant-menu-item-icon {
    margin-left: 0;
  }

  .ant-menu-item .ant-menu-item-icon + span {
    margin: 0 20px 0 0;
  }

  .ant-menu-inline .ant-menu-item::after {
    border: none;
  }
}

.ant-menu-inline > .ant-menu-submenu {
  border-bottom: 1px solid $base-color4;
  margin-bottom: 80px !important;
  color: $base-color-text-3;
}

.ant-menu-inline > .ant-menu-submenu:hover {
    background: $base-color5 !important;
    color: $base-color-text-3 !important;
}

.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 70px;
  width: 210px;
  margin: 0 auto;
  color: $base-color-text-3;

  &:hover {
    background: $base-color5 !important;
    color: $base-color-text-3 !important;
  }
}