@mixin overview-card {
  display: flex;
  flex-direction: row;
  margin: 10px;
  height: 360px;
  filter: $card-box-shadow;
}

.site-layout-page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  width: 290px;
  .ant-col {
    width: 100%;
  }
}

.site-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding: 15px 55px 0px 55px;

  .ant-row {
    .spinner {
      left: calc(50%);
    }
  }
}

.site-map {
  height: 100%;
  width: 100%;
  padding: 10px;
}

.site-overview-card {
  @include overview-card;
  .ant-card-body {
    display: flex;
    padding: 0;
    width: 100%;
    height: 100%;
  }
}

.site-info-overview-card {
  margin: 10px 0;
  @extend .site-overview-card;
}

.description {
  color: $base-color-text-6;
  margin: 30px 20px 20px 20px;
  padding-left: 10px;
  width: 360px;
}

.site-card-info-icon {
  margin-left: 2px;
}

.site-info-card-description {
  color: $base-color;
  margin: 30px 20px 20px 30px;
  width: 90%;
}

.ant-card-cover div {
  border-radius: 10px 0 0 10px;
  height: 100%;
  width: 100%;
}

.parameters-image {
  width: fit-content;
  max-width: 720px;
}

.document-image {
  padding-left: 10px;
  width: 60px;
}

.ant-card {
  border-radius: 10px;
}

.ant-card-title {
  font-size: 14px;
  color: $base-color-text-6;
}

.ant-card-title-activity {
  font-weight: 500;
  font-size: 15px;
  color: $base-color;
}

.ant-card-title-analytics {
  @extend .ant-card-title-activity;
  color: $base-color-text-6;
}

.ant-card-title-link {
  font-weight: 500;
  font-size: 16px;
  padding: 0;
  color: $avatar-color;
  background-color: transparent;
  height: fit-content;
  width: fit-content;
  &:hover {
    background-color: transparent;
    color: $base-color-2-hover;
    transition: all 0.3s;
  }
}

.ant-card-title-2 {
  font-weight: 400;
  font-size: 14px;
  color: $base-color-text-6;
  margin: 0;
}

.ant-card-title-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
}

.ant-list-footer {
  padding: 0;
  height: 100%;
}

.ant-card-title-wrapper-dashboard {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px;
  border-bottom: 2px solid $base-color5;
}

.ant-card-title-wrapper-2 {
  width: 100%;
  padding: 20px 20px 0 20px;
}

.ant-card-body-info {
  font-size: 26px;
  font-weight: 400;
  color: $base-color;
}

.ant-card-body-info-2 {
  font-size: 16px;
  font-weight: 400;
  color: $base-color-text-6;
}

.ant-card-body-info-3 {
  display: flex;
  gap: 25px;
  
  .value-wrapper {
    font-size: 26px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
  }
}

.ant-card-title-wrapper-3 {
  width: 100%;
  padding: 15px 20px 0 20px;
}

.ant-card-body-wrapper {
  width: 100%;
  padding-top: 20px;
  display: flex;
  justify-content: center;
}

.ant-card-body-wrapper-2 {
  width: 100%;
  height: 100%;
  padding-left: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ant-card-body-wrapper-list {
  width: 100%;
  padding: 5px 20px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .spinner {
		left: 50%;
	}
}

.ant-card-body-wrapper-list-molina {
  @extend .ant-card-body-wrapper-list;

  .ant-list-item:first-child {
    padding-top: 100px;
  }
}

.ant-card-infos {
  font-weight: 500;
  font-size: 14px;
  color: $base-color;
  width: 100%;
}

.ant-card-activity-title {
  @extend .ant-card-infos;
  font-weight: 500;
}

.ant-card-infos-dashboard {
  font-weight: 500;
  font-size: 14px;
  color: $base-color-text-6;
  width: 100%;
}

.dashboard-info-card {
  display: flex;
  height: 130px;
  margin: 10px 0 10px 0;
  filter: $card-box-shadow;
  .ant-card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    height: 100%;
    width: 100%;
  }
}

.dashboard-info-card-users {
  display: flex;
  height: 510px;
  margin: 10px 0 10px 0;
  filter: $card-box-shadow;
  .ant-card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    height: 100%;
    width: 100%;
  }
}

.dashboard-info-card-users-molina {
  @extend .dashboard-info-card-users;
  height: 360px;
}

.site-info-card-content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.site-info-card-2 {
  display: flex;
  height: 360px;
  margin: 10px 0 10px 0;
  filter: $card-box-shadow;
  .ant-card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    height: 100%;
    width: 100%;
  }

  .selector-bordered {
    border: 1px solid $base-color4;
    border-radius: 10px;
    margin-left: 5px;
    .select-day-rounded-analytics {
      filter: none;
    }
  }
}

.selector-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.site-info-card-gas {
  @extend .site-info-card-2;
  height: fit-content;
}

.site-info-card {
  display: flex;
  height: 300px;
  margin: 10px 0 10px 0;
  filter: $card-box-shadow;
  .ant-card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px;
    height: 100%;
    width: 100%;
  }
}

.ant-list-item-meta-title {
  margin-bottom: 0;
}

.cut-text-lg {
  @include cut-text-md;
  width: 165px;
  color: $base-color-text-6;
}

.cut-text-overview {
  @include cut-text-md;
  font-size: 20px;
  width: 190px;
}

@media (max-width: 1400px) {
  .activity-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media (min-width: 1600px) {
  .activity-wrapper {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
}

.activity-padding {
  margin-right: 5px;
  color: $base-color-text-6;
}

.activity-date {
  color: $base-color-text-6;
}
