td {
	border-style: none;
	background-color: white;
	margin: 30px 0;
}

.ant-table-thead > tr > th {
	background-color: white;
	color: $base-color-text-6;
	font-weight: 600;
	padding: 25px 10px;
}

.ant-table-analytics {
	.ant-table-thead > tr > th {
		background-color: transparent;
		color: transparent;
		padding: 0;
	}
	.ant-table {
		border-radius: 10px;
		filter: $card-box-shadow;
	}
	.ant-table-body {
		border-radius: 10px;
	}
	.ant-table-cell {
		color: $base-color;
	}
}

.ant-table-body {
	overflow-y: auto !important;
}

.ant-table-analytics {
	.ant-table-tbody > tr > td {
		padding: 30px 0;
	}

	table tr:first-child td:first-child {
		border-radius: 10px 0 0 0 !important;
	}
	table tr:first-child td:last-child {
		border-radius: 0 10px 0 0 !important;
	}
	.ant-table-tbody > tr.ant-table-row:hover > td {
		background: $table-hover;
		cursor: pointer;
	}
}

.ant-table-hover {
	.ant-table-tbody > tr.ant-table-row:hover > td {
		background: $table-hover;
		cursor: pointer;
	}
}

.ant-table {
	filter: $card-box-shadow;
	background-color: transparent;
}

.ant-table table {
	background-color: transparent;
	width: 100%;
	text-align: left;
	border-radius: 2px 2px 0 0;
}

.ant-table-column-sorters span {
	font-weight: 600;
}

.ant-table-filter-column-title {
	font-weight: 600;
}

.ant-table-footer-wrapper {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	padding: 20px 30px;
}

.ant-table-footer {
	background: white;
	border-bottom-left-radius: 25px;
	border-bottom-right-radius: 25px;
	padding: 0;
}

.ant-checkbox-checked::after {
	border-radius: 50%;
}

.ant-checkbox-inner {
	border-radius: 50%;
	padding: 8px;
}

.ant-table-tbody > tr.ant-table-row-selected > td {
	background: white;
}

.analytics-table-actions {
	color: $base-color-text-6;
	font-weight: 500;
}

.analytics-table-actions-selected {
	color: $base-color-text-6;
	font-weight: 500;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
	background: white;
}

.ant-table-filter-trigger-container {
	padding: 0 10px;
	margin: 0 20px;
}

.users-table-name {
	font-weight: 500;
	color: $base-color;
	padding-bottom: 5px;
}

.ant-table-cell {
	color: $base-color-text-6;
	a {
		color: $base-color;
		font-weight: 500;
		&:hover {
			color: $base-color-2-hover;
		}
	}
}

.input-gap {
	padding-right: 5px;
}

.ant-pagination-prev {
	height: 34px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
}

.ant-pagination-next {
	height: 34px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
}

.ant-pagination-jump-next {
	height: 34px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
	border-radius: 5px;
	border: none;
	color: $base-color-text-6;
	display: inline-flex;
	justify-content: center;
	align-items: center;

	&:hover {
		color: $base-color-2;
	}
}

.ant-pagination-item.ant-pagination-item-active {
	background: $base-color3;
	color: $base-color-2;
	border: none;
	a {
		color: $base-color-2;
	}
}

.ant-pagination-item {
	border-radius: 5px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	height: 34px;
	border: none;
	a {
		font-size: 15px;
		color: $base-color-text-6;
		&:hover {
			color: $base-color-2;
		}
	}
}

.ant-pagination-item-container:hover {
	color: $base-color-2;
}

.ant-table-cell {
	table th:first-child {
		border-radius: 10px 0 0 0 !important;
	}
	table th:last-child {
		border-radius: 0 10px 0 0 !important;
	}
	table tr:last-child td:first-child {
		border-radius: 0 0 0 10px !important;
	}
	table tr:last-child td:last-child {
		border-radius: 0 0 10px 10px !important;
	}
}

.ant-table-cell-log {
	table th:first-child {
		border-radius: 0 !important;
	}
	table th:last-child {
		border-radius: 0 !important;
	}
	table tr:last-child td:first-child {
		border-radius: 0 0 0 10px !important;
	}
	table tr:last-child td:last-child {
		border-radius: 0 0 10px 10px !important;
	}
}

.ant-table-cell-pagination {
	table th:first-child {
		border-radius: 10px 0 0 0 !important;
	}
	table th:last-child {
		border-radius: 0 10px 0 0 !important;
	}
	table tr:last-child td:first-child {
		border-radius: 0 !important;
	}
	table tr:last-child td:last-child {
		border-radius: 0 !important;
	}
}

.ant-table-cell-pagination-log {
	table th:first-child {
		border-radius: 0 !important;
	}
	table th:last-child {
		border-radius: 0 !important;
	}
	table tr:last-child td:first-child {
		border-radius: 0 !important;
	}
	table tr:last-child td:last-child {
		border-radius: 0 !important;
	}
}

.table-click td {
	cursor: pointer;
}

.ant-table-cell {
	.table-item-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		font-weight: normal;
		font-size: 14px;
		line-height: 16px;
		.primary-row {
			color: $base-color;
			margin-bottom: 10px;
		}
		.secondary-row {
			color: #878992;
		}
	}
}

button.ant-table-row-expand-icon:hover {
	background-color: #ffffff;
}

.ant-table-pagination.ant-pagination {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	background-color: white;
	margin: 0;
	padding: 16px;
	border-radius: 0 0 10px 10px !important;
	filter: $card-box-shadow;
}

.ant-pagination-options .ant-select-single .ant-select-selector {
	border-radius: 10px;
	border: 1px solid $base-color-text-5;
	color: $base-color-text-6;
	.ant-select-selection-item {
		padding: 0 28px 0 10px;
	}
}

.ant-select-focused:not(.ant-select-disabled) .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
	border-color: $base-color-2;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
	border-color: $base-color-2;
}

.ant-select-item-option-content {
	color: $base-color-text-6;
}

.ant-checkbox-checked .ant-checkbox-inner {
	background-color: $base-color-2;
	border-color: $base-color-2;
}

.ant-checkbox-checked::after {
	border: 1px solid $base-color-2-hover;
}

.table-total {
	position: absolute;
	color: $base-color-text-6;
	left: 0;
	padding: 8px 0 0 10px;
	margin-left: 10px;
}

.download {
	font-size: 16px;
	font-weight: 500;
	color: $base-color;
}

.table-header-title {
	font-size: 20px;
	font-weight: 600;
}

.log-table-header {
	width: 100%;
	padding: 30px 20px;
	background: white;
	border-radius: 10px 10px 0 0 !important;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid $select-border-color;
}

.notification-badge-wrapper {
	padding-left: 22px;
}

.ant-table-thead th.ant-table-column-sort {
	background: white;
}

td.ant-table-column-sort {
	background: white;
}

.table-cell-primary {
	color: $base-color;
	font-weight: 500;
}

.ant-pagination-jump-prev, .ant-pagination-jump-next {
	height: fit-content;
	.ant-pagination-item-container .ant-pagination-item-link-icon {
		color: $base-color-2 !important;
	} 
}

.ant-table-cell {
	.ant-progress-text {
		color: $base-color-text-6;
	}

	.ant-progress-bg {
		background: $progress-bar-color;
	}
}
