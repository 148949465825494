.ant-tabs-content-holder {
  -ms-flex: auto;
  flex: auto;
  min-width: 0;
  min-height: 100%;
  padding: 30px 5px 0 5px;
}

.transactions-tab-wrapper {
  padding: 10px 0;
  height: 100%;
}

.sensor-image-cover {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $base-color4;
}

.sensor-image {
  max-width: 250px;
  display: block; /* remove extra space below image */
  margin: 10px;
}

.GHG-image {
  width: 55px;
}

.sensor-info-card-vertical {
  display: flex;
  height: 562px;
  margin: 10px 0 10px 0;
  padding: 0 10px;
  filter: $card-box-shadow;
  .ant-card-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    width: 100%;
  }
}

.sensor-info-card-horizontal {
  @include overview-card;
  margin: 10px 0;
  .ant-card-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 30px 30px;
    width: 100%;
  }
}

.sensor-info-card {
  display: flex;
  height: 160px;
  margin: 10px 0 10px 0;
  filter: $card-box-shadow;
  .ant-card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    height: 100%;
    width: 100%;
  }
}

// V2 ant-tabs-styles //

.ant-tabs > .ant-tabs-nav {
  margin: 0;

  .ant-tabs-nav-wrap {
    height: 70px;
  }
}

.ant-tabs-top > .ant-tabs-nav::before {
  border: 1px solid $base-color4;
}

.ant-tabs-tab {
  color: $base-color-text-6;

  &:first-child {
    margin-left: 10px !important;
	}
  
  &:hover {
    color: $base-color;
    font-weight: 600;
  }
}

.ant-tabs-ink-bar {
  border-bottom: 2px solid $base-color-2;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: $base-color;
  font-weight: 500;
}

.ant-tabs-tab-btn:focus,
.ant-tabs-tab-btn:active {
  color: $base-color;
}

.ant-select-selection-item {
  flex: none;
  padding: 0;
  font-weight: 500;
}
