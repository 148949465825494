.overview-page-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px 220px 40px 220px;
}

// Search bar
$tl: 0.5s; // transition length

.search-box {
  transition: width $tl, border-radius $tl, background $tl, box-shadow $tl;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  background: transparent;
  & + label .search-icon {
    color: $base-color-text-6;
  }
  &:hover {
    color: $base-color-text-6;
    background: $base-color5;
    box-shadow: 0 0 0 2px $base-color4;
    & + label .search-icon {
      color: $base-color-2;
    }
  }
  &:focus {
    transition: width $tl cubic-bezier(0, 1.22, 0.66, 1.39), border-radius $tl, background $tl;
    border: none;
    outline: none;
    box-shadow: none;
    padding-left: 15px;
    cursor: text;
    width: 300px;
    border-radius: auto;
    background: $base-color5;
    color: $base-color;
    & + label .search-icon {
      color: $base-color-2;
    }
  }
  &:not(:focus) {
    text-indent: -5000px;
  }
}

::-webkit-input-placeholder {
  color: $base-color-text-6;
}

#search-submit {
  display: none;
}

.search-icon {
  position: relative;
  left: -30px;
  top: 10px;
  color: $base-color-hover;
  cursor: pointer;
}
