$base-color: #06273a;
$base-color-hover: #02121d;

$base-color-2: #14c6bb;
$base-color-2-hover: #34afa7;

$base-color3: #e7fcfb;
$base-color4: #eaf1ff;
$base-color5: #f6f8fc;

$ant-btn-bg: #3a3d42;

$base-color2: #f6f7fa;
$base-color2-hover: #dfdfdf;

$select-border-color: #dfe2e2;

$table-hover: #f6f8fc;

$base-color-text: #5c5f6a;
$base-color-text-2: #aab8c2;
$base-color-text-3: #7b94ba;
$base-color-text-4: #8d8e93;
$base-color-text-5: #d9d9d9;
$base-color-text-6: #7b94ba;

$delete-btn: #88909b;
$delete-btn-hover: #dc817c;
$delete-text: #c94040;

$google-color: #4285f4;
$google-color-hover: #2363ca;

$scribehub-color: #40b0cf;
$scribehub-color-hover: #3899b4;

$connected-color: #e7fcfb;
$connected-color-text: #14c6bb;

$offline-color: #ffeded;
$offline-color-text: #ff5252;

$info-alert-color: #14c6bb;
$info-alert-color-bg: #e7fcfb;

$avatar-color: #14c6bb;
$avatar-color2: #f25c9b;
$avatar-color3: #ff7474;
$avatar-color4: #fdab31;
$avatar-color5: #566ee7;
$avatar-color6: #31c0fd;
$avatar-color7: #69df7c;

$chart-color1: #5f79ff;
$chart-color2: #14c6bb;
$chart-color3: #31c0fd;
$chart-color4: #f25c9b;
$chart-color-sim: #fb8f67;

$avatar-color-hover: #57b1ff;

$progress-bar-color: #A2F4EF;

$card-box-shadow: drop-shadow(0px 2px 4px rgba(35, 54, 58, 0.08));
