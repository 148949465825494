@mixin font-size($size) {
  font-size: $size;

  // sass-lint:disable no-duplicate-properties
  font-size: #{$size / 16px}rem;
}

@mixin clearfix() {
  &::after {
    display: block;
    clear: both;
    content: '';
  }
}

@mixin header-footer-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 100px;
  z-index: 100;
  width: 100%;
}

@mixin cut-text-md {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 165px;
  font-size: 15px;
  white-space: nowrap;
  justify-content: center;
  color: $base-color;
  font-weight: 600;
}
