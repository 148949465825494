.ant-modal-header {
  background: $delete-btn-hover;
  height: 56px;
  border-radius: 8px 8px 0px 0px;
}

.input-modal .ant-modal-header {
  background: white;
  height: 56px;
  border-radius: 8px 8px 0px 0px;
  border-bottom: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.input-modal .ant-modal-close {
  display: flex;
}

.ant-modal-content {
  border-radius: 8px;
}

.ant-modal-close {
  width: 60px;
  display: none;
  &:hover {
    background: transparent;
  }
}

.ant-modal-footer {
  border-top: 0;
  padding: 0 24px 24px 24px;
}

.warning-icon {
  color: #4d2a28;
  fill: #4d2a28;
  font-size: 22px;
  padding-right: 10px;
}

.ant-btn-lg1 {
  width: 120px;
  height: 42px;
  font-weight: 500;
  background: white;
  border: 1px solid $base-color-text-2;
  box-sizing: border-box;
  border-radius: 10px;
  &:hover {
    background: white;
    border: 1px solid $avatar-color;
    color: $avatar-color;
    transition: all 0.5s;
  }
}

.warning-text {
  color: #4d2a28;
  fill: #4d2a28;
  font-size: 15px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.modal-footer-hidden-button {
  display: none;
}

.cancel-button-props {
  color: $delete-btn;
  background-color: transparent;
  font-weight: 500;
  border: none;
  box-shadow: none;
  border-radius: 20px;

  &:hover {
    color: $delete-btn-hover;
    background: transparent;
    transition: all 0.5s;
  }
}

.ok-button-props {
  color: white;
  background-color: $base-color;
  font-weight: 500;
  border: none;
  box-shadow: none;
  border-radius: 20px;

  &:hover {
    color: white;
    background: $base-color-hover;
    transition: all 0.5s;
  }
}

.input-modal-title {
  color: $base-color;
  font-size: 16px;
  font-weight: 500;
}

.input-modal-field {
  border-radius: 10px;
  height: 40px;
}
